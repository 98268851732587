<template>
  <b-row
      class="d-flex flex-row align-items-center justify-content-start w-100"
      no-gutters
  >
    <!-- Heading -->
    <b-row
        class="d-flex flex-row align-items-start justify-content-start"
        no-gutters
    >
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-dark font-weight-bold font-primary">
          Tax Management
        </h5>
      </b-col>
    </b-row>
    <!-- Vat Rate -->
    <b-row
        class="d-flex flex-row align-items-center justify-content-start w-100 mt-5 ml-2"
        no-gutters
    >
      <b-col lg="3" class="mr-4">
        <p>{{ checkCountry }}</p>
      </b-col>
      <b-col lg="3">
        <FormInput
            groupId="tex-management"
            id="tex-management-vat"
            class="text-prime-gray rounded-2"
            v-model="vatRate.value"
            isRequired
            trim
            form="login-form"
            type="text"
            :isDisabled="status[0].disable"
        ></FormInput>
      </b-col>
      <b-col lg="3">
        <div
            class="d-flex flex-row align-items-starch justify-content-center h-100 mb-3"
        >
          <h6 class="m-0 mr-5 text-muted">Set Default</h6>
          <b-form-checkbox
              :id="status[0].id"
              v-model="status[0].status"
              value="accepted"
              unchecked-value="not_accepted"
              name="checkbox-1"
              @change="(e) => changeStatus(e, status[0].id)"
              switch
              :disabled="getUserRole.role.includes('User')"
          >
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <!-- VAT Exempt Rate -->
    <b-row
        class="d-flex flex-row align-items-center justify-content-start w-100 ml-2"
        no-gutters
    >
      <b-col lg="3" class="mr-4">
        <p v-if="this.country == 'Fiji'">VAT Exempt Rate</p>
        <p v-else>GST Exempt Rate</p>
      </b-col>
      <b-col lg="3">
        <FormInput
            groupId="tex-management"
            id="tex-management-vat"
            class="text-prime-gray rounded-2"
            isRequired
            v-model="vatExemptRate.value"
            trim
            form="login-form"
            type="text"
            :isDisabled="status[1].disable"
        ></FormInput>
      </b-col>
      <b-col lg="3">
        <div
            class="d-flex flex-row align-items-starch justify-content-center h-100 mb-3"
        >
          <h6 class="m-0 mr-5 text-muted">Set Default</h6>
          <b-form-checkbox
              :id="status[1].id"
              v-model="status[1].status"
              value="accepted"
              unchecked-value="not_accepted"
              name="checkbox-2"
              switch
              @change="(e) => changeStatus(e, status[1].id)"
              :disabled="getUserRole.role.includes('User')"
          >
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <!-- VAT Sales Rate -->
    <b-row
        class="d-flex flex-row align-items-center justify-content-start w-100 ml-2"
        no-gutters
        v-if="country === 'Fiji'"
    >
      <b-col lg="3" class="mr-4">
        <p>VAT Sales Rate</p>
      </b-col>
      <b-col lg="3">
        <FormInput
            groupId="tex-management"
            id="tex-management-vat"
            class="text-prime-gray rounded-2"
            isRequired
            trim
            form="login-form"
            type="text"
            v-model="vaTSalesRate.value"
            :isDisabled="status[2].disable"
        ></FormInput>
      </b-col>
      <b-col lg="3">
        <div
            class="d-flex flex-row align-items-starch justify-content-center h-100 mb-3"
        >
          <h6 class="m-0 mr-5 text-muted">Set Default</h6>
          <b-form-checkbox
              :id="status[2].id"
              v-model="status[2].status"
              value="accepted"
              unchecked-value="not_accepted"
              name="checkbox-3"
              switch
              @change="(e) => changeStatus(e, status[2].id)"
              :disabled="getUserRole.role.includes('User')"
          >
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <!-- Customized Rate -->
    <b-row
        class="d-flex flex-row align-items-center justify-content-start w-100 ml-2"
        no-gutters
    >
      <b-col lg="3" class="mr-4">
        <FormInput
            groupId="tex-management"
            id="tex-customized-rate"
            class="text-prime-gray rounded-2"
            label="Customized Rate"
            isRequired
            trim
            form="login-form"
            type="text"
            v-model="customizedRate.label"
            :isDisabled="status[3].disable"
        ></FormInput>
      </b-col>
      <b-col lg="3">
        <FormInput
            groupId="tex-management"
            id="tex-management-vat"
            class="text-prime-gray rounded-2"
            isRequired
            trim
            form="login-form"
            type="text"
            v-model="customizedRate.value"
            :isDisabled="status[3].disable"
        ></FormInput>
      </b-col>
      <b-col lg="3">
        <div
            class="d-flex flex-row align-items-starch justify-content-center h-100 mb-3"
        >
          <h6 class="m-0 mr-5 text-muted">Set Default</h6>
          <b-form-checkbox
              :id="status[3].id"
              v-model="status[3].status"
              value="accepted"
              unchecked-value="not_accepted"
              name="checkbox-4"
              switch
              @change="(e) => changeStatus(e, status[3].id)"
              :disabled="getUserRole.role.includes('User')"
          >
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>

    <!-- Save Button -->
    <b-row
        class="d-flex flex-row align-items-center justify-content-end w-100"
        no-gutters
    >
      <b-col lg="4" class="ml-4">
        <b-button
            variant="main-green"
            class="save-btn w-50 d-flex flex-row align-items-center justify-content-center border-0 rounded-lg"
            pill
            @click="savaTaxValue"
            :disabled="getUserRole.role.includes('User')"
        >
          <span class="d-inline text-center">Save</span>
        </b-button>
      </b-col>
    </b-row>
  </b-row>
</template>

<script>
import FormInput from "@/components/Form/FormInput";
import {
  UpdateCurrentWorkspace,
  GetCurrentWorkspace,
} from "@/services/settingsbusiness.service";
import {mapGetters} from "vuex";

export default {
  name: "TaxManagement",
  components: {
    FormInput,
  },
  data() {
    return {
      currentWorkSpace: null,
      currency: localStorage.getItem("CURRENCY"),
      Currency: null,
      country: null,
      status: [
        {
          status: "accepted",
          id: "",
          disable: true,
        },
        {
          status: "not_accepted",
          id: "VAT_Exempt_Rate",
          disable: true,
        },
        {
          status: "not_accepted",
          id: "VAT_Sales_Rate",
          disable: true,
        },
        {
          status: "not_accepted",
          id: "Customized_rate",
          disable: false,
        },
      ],
      vatRate: {
        value: "15%",
        label: "",
      },
      vatExemptRate: {
        value: "0%",
        label: "VAT Exempt Rate",
      },
      vaTSalesRate: {value: "9%", label: "VAT Sales Rate"},
      customizedRate: {value: "0%", label: null},
    };
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole"
    }),
    checkCountry() {
      if (this.country === "Fiji") {
        return "VAT Rate";
      } else {
        return "GST Rate";
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    // status(val) {
    //   console.log("🚀 ~ file: TaxManagement.vue:182 ~ status ~ val:", val);
    // },
  },
  async mounted() {
    const res = await GetCurrentWorkspace();
    console.log("🚀 ~ file: TaxManagement.vue:270 ~ mounted ~ res:", res.data);
    this.currentWorkSpace = res.data;
    this.country = res.data.country;
    console.log("country",this.country)
    console.log("datatax",res.data)

    if (!res.data.vat_label || res.data.vat_label) {
      if (this.country === "Fiji") {
        this.status[0].id = "Vat_Rate";
        this.vatRate.label = "Vat Rate";

      } else {
        this.status[0].id = "GST_Rate";
        this.vatRate.label = "GST Rate";
      }
    }
    if (res.data.vat_label) {
      const idList = this.status.map((item) => item.id);
      idList.includes(res.data.vat_label.replace(/ /g, "_"));
      if (idList.includes(res.data.vat_label.replace(/ /g, "_"))) {
        for (let step = 0; step < this.status.length; step++) {
          if (this.status[step].id === res.data.vat_label.replace(/ /g, "_")) {
            this.status[step].status = "accepted";
            // this.status[step].disable = false;
          } else {
            this.status[step].status = "not_accepted";
            this.status[step].disable = true;
          }
        }
      } else {
        for (let step = 0; step < this.status.length; step++) {
          if (this.status[step].id === "Customized_rate") {
            this.status[step].status = "accepted";
            this.status[step].disable = false;
          } else {
            this.status[step].status = "not_accepted";
            // this.status[step].disable = true;
          }
        }
      }
      if (this.vatRate.label === res.data.vat_label) {
        this.vatRate.value = res.data.vat_rate.toString() + "%";
      } else if (this.vatExemptRate.label === res.data.vat_label) {
        this.vatExemptRate.value = res.data.vat_rate.toString() + "%";
      } else if (this.vaTSalesRate.label === res.data.vat_label) {
        this.vatExemptRate.value = res.data.vat_rate.toString() + "%";
      } else {
        this.customizedRate.value = res.data.vat_rate.toString() + "%";
        this.customizedRate.label = res.data.vat_label;
      }
    }
  },
  methods: {
    changeStatus(newStatus, id) {
      console.log(this.status);
      for (let step = 0; step < this.status.length; step++) {
        if (this.status[step].id != id) {
          this.status[step].status = "not_accepted";
          // this.status[step].disable = false;
        } else {
          this.status[step].status = newStatus;
          // this.status[step].disable = true;
        }
      }
      this.changeInputDisableStatus();
    },
    changeInputDisableStatus() {
      console.log(this.status);
      for (let step = 0; step < this.status.length; step++) {
        if (
            this.status[step].status === "not_accepted" &&
            this.status[step].id === "Customized_rate"
        ) {
          this.status[step].disable = true;
        } else if (
            this.status[step].status === "accepted" &&
            this.status[step].id === "Customized_rate"
        ) {
          this.status[step].disable = false;
        }
      }
    },
    async savaTaxValue() {
      if (this.errorFn(this.status)) {
        let value;
        let label;

        const obj = this.status.find((item) => {
          if (item.status === "accepted") return item;
        });
        const id = obj.id.replace(/_/g, " ");
        console.log(this.vatRate.label === id);
        console.log(this.vatRate);
        if (this.vatRate.label === id) {
          value = this.vatRate.value;
          label = id;
        } else if (this.vatExemptRate.label === id) {
          value = this.vatExemptRate.value;
          label = id;
        } else if (this.vaTSalesRate.label === id) {
          console.log(this.vaTSalesRate);
          value = this.vaTSalesRate.value;
          label = id;
        } else {
          value = this.customizedRate.value;
          label = this.customizedRate.label;
        }
        console.log(value);
        const payload = {
          ...this.currentWorkSpace,
          vat_rate: +value.replace(/%/g, ""),
          vat_label: label,
        };
        const res = await UpdateCurrentWorkspace(payload);
        if (res.status == 200) {
          let payloadNotify = {
            isToast: true,
            title: "Tax Management",
            content: "Vat rate set successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } else {
          let payloadNotify = {
            isToast: true,
            title: "Tax Management",
            content: "Something went wrong",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } else {
        let payloadNotify = {
          isToast: true,
          title: "Tax Management",
          content: "Please set a tax value rate as default",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    errorFn() {
      const check = this.status.map((item) => item.status);
      if (check.includes("accepted")) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss">
.save-btn {
  padding: 10px 30px 10px 30px;
  margin-left: 18%;
}

//Checked
// .custom-control-input:checked ~ .custom-control-label::before {
//   border-color: #0b9b7d;
//   background-color: #fff;
//   border-width: 2px;
//   height: 10px;
//   width: 16px;
// }

// .custom-switch .custom-control-label::after {
//   width: calc(1.1rem - 4px);
//   height: calc(1.1rem - 4px);
//   background: #fff;
//   border: 2px solid #0b9b7d;
// }

// .custom-switch .custom-control-label::after {
//   transform: translate(-8px, -3px);
// }

// //Unchecked
// .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
//   background-color: #0b9b7d;
//   transform: translate(8px, -3px);
// }

// .custom-switch .custom-control-label::before {
//   height: 10px;
//   width: 16px;
//   border-color: #0b9b7d;
//   border-width: 2px;
// }
</style>
