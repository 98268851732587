import axios from "axios";


export const CreateMainFolder = async (payload) => {
    try {
      return await axios.post(`WorkPaperFolders/createMainFolder`, payload);
    } catch (error) {
      return error;
    }
  };
  export const CreateSubFolder = async (payload) => {
    try {
      return await axios.post(`WorkPaperFolders/createSubFolder`, payload);
    } catch (error) {
      return error;
    }
  };

export const GetAllMainFolders = async () => {
    try {
      return await axios.get("WorkPaperFolders/");
    } catch (error) {
      return error;
    }
  };

  export const GetAllSubFolders = async (payload) => {
    try{
    return await axios.get(`WorkPaperFolders/getsubfolders/${payload.id}`);
    }catch (error) {
      return error;
    }


};
export const DeleteSubFolder = async (payload) => {
  try {
    return await axios.delete(`WorkPaperFolders/deleteSubFolder/${payload.id}`);
  } catch (error) {
    return error;
  }
};
export const DeleteMainFolder = async (payload) => {
  try {
    return await axios.delete(`WorkPaperFolders/deleteMainFolder/${payload.id}`);
  } catch (error) {
    return error;
  }
};
export const CreateFileUpload = async (payload) => {
  try {
    return await axios.post(`WorkPaperFolders/createPDF`, payload);
  } catch (error) {
    return error;
  }
};

export const GetAllFiles = async (payload) => {
  try{
  return await axios.get(`WorkPaperFolders/getPdfs/${payload.id}`);
  }catch (error) {
    return error;
  }


};
export const DeleteUploadFile = async (payload) => {
  try {
    return await axios.delete(`WorkPaperFolders/deletePdf/${payload.id}`);
  } catch (error) {
    return error;
  }
};
export const SearchFiles = ({ search }) => {
  return axios.get(`WorkPaperFolders/search`, {
    params: { search  },
  });
};

export const PdfUpload = async (payload) => {
  try {
    return await axios.post(`WorkPaperFolders/pdfs`, payload);
  } catch (error) {
    return error;
  }
};

export const UpdateMainFolder = async (payload) => {
  try {
    return await axios.put(`WorkPaperFolders/mainfolderupdate/${payload.id}`, payload);
  } catch (error) {
    return error;
  }
};
export const UpdateSubFolder = async (payload) => {
  try {
    return await axios.put(`WorkPaperFolders/subfolderupdate/${payload.id}`, payload);
  } catch (error) {
    return error;
  }
};

export const GetMainFolderDetails = async ({ id  }) => {
    
  return await axios.get(`WorkPaperFolders/`,{ params: { id },});
};

export const GetSubFolderDetails = async ({ id  }, payload) => {
    
  return await axios.get(`WorkPaperFolders/getsubfolders/${payload.id}`,{
    params: { id  },
  });
};
