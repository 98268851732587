<template>
  <b-row
    class="d-flex flex-row align-items-center justify-content-between w-100"
    no-gutters
  >
    <!-- Contact Title row  -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-start"
      no-gutters
    >
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-dark font-weight-bold font-primary">
          Templates
        </h5>
      </b-col>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-center justify-content-start w-100 mt-5"
      no-gutters
    >
      <b-col cols="6">
        <span class="py-2 text-dark font-weight-normal"
          >Download Figa's Bank Statement Format</span
        >
      </b-col>
      <b-col cols="6">
        <b-button
          variant="outline-main-green"
          pill
          class="my-2 my-lg-0 ml-4 border-main-green action-btn"
          @click="downloadStatement"
        >
          <span class="d-inline mx-2 font-weight-bold">Download</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row
      class="d-flex flex-row align-items-center justify-content-start w-100 mt-5"
      no-gutters
    >
      <b-col cols="6">
        <span class="py-2 text-dark font-weight-normal"
          >Download Figa's Trial Balance Format</span
        >
      </b-col>
      <b-col cols="6">
        <b-button
          variant="outline-main-green"
          pill
          class="my-2 my-lg-0 ml-4 border-main-green action-btn"
          @click="downloadTrialBalance"
        >
          <span class="d-inline mx-2 font-weight-bold">Download</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row
      class="d-flex flex-row align-items-center justify-content-start w-100 mt-5"
      no-gutters
    >
      <b-col cols="6">
        <span class="py-2 text-dark font-weight-normal"
          >Download Figa's Chart of Accounts Format</span
        >
      </b-col>
      <b-col cols="6">
        <b-button
          variant="outline-main-green"
          pill
          class="my-2 my-lg-0 ml-4 border-main-green action-btn"
          @click="downloadCOA"
        >
          <span class="d-inline mx-2 font-weight-bold">Download</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-center justify-content-start w-100 mt-5"
      no-gutters
    >
      <b-col cols="6">
        <span class="py-2 text-dark font-weight-normal"
          >Download Figa's Customer Format</span
        >
      </b-col>
      <b-col cols="6">
        <b-button
          variant="outline-main-green"
          pill
          class="my-2 my-lg-0 ml-4 border-main-green action-btn"
          @click="downloadCustomer"
        >
          <span class="d-inline mx-2 font-weight-bold">Download</span>
        </b-button>
      </b-col>
    </b-row>
  </b-row>
</template>
<script>
import { baseURL } from "@/services/config";
export default {
  name: "Templates",
  components: {},
  data() {
    return {};
  },

  methods: {
    downloadStatement() {
      // Replace the URL with the actual URL of the statement file
      const url =
        "https://figadev.s3.amazonaws.com/Figa+Bank+Statement+Template.csv";

      // Create a new anchor element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "Figa Bank Statement Template.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadTrialBalance() {
      // Replace the URL with the actual URL of the statement file
      const url =
        "https://figadev.s3.amazonaws.com/Figa+Trial+Balance+Template.csv";

      // Create a new anchor element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "Figa Trial Balance Template.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadCOA() {
      // Replace the URL with the actual URL of the statement file
      const url = "https://figadev.s3.amazonaws.com/ChartOfAccounts.csv";

      // Create a new anchor element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "Figa CoA Template.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadCustomer() {
      window.open(`${baseURL}/downloadCustomerCsvTemplate/`);
    },
  },
};
</script>
<style scoped>
/* table card */
.table-card-row {
  height: 100%;
}
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}
/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}
.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}
.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}
.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}
.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.table-container-row .table-element {
  min-width: 1000px;
}
/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}
.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}
.table-element >>> .table-body td {
  vertical-align: middle;
}
.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}
.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}
/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}
/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}
/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--main-green);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}
/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}
/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .edit-btn > .edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}
/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: var(--main-green);
}
.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}
/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}
/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}
.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}
/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}
/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}
.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}
.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}
/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}
/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}
.table-pagination >>> .custom-page-item button:hover {
  color: var(--main-green);
  border-color: var(--main-green);
  background-color: var(--light);
}
.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}
.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}
.action-btn {
  height: 40px;
  min-width: 80px;
  border-width: 2px;
}
.action-btn:hover {
  background: var(--main-green);
  box-shadow: inset 0px 0px 10px #ffffff;
  color: #ffffff;
  border: none;
  font-weight: normal;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }
  .action-btn {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .action-btn {
    width: 40%;
  }
}

@media (min-width: 992px) {
  .action-btn {
    width: auto;
  }
}
</style>
