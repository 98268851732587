<template>
  <b-row class="d-flex flex-row align-items-start justify-content-start w-100" no-gutters>
    <!-- Contact Title row  -->
    <b-row class="d-flex flex-row align-items-start justify-content-start" no-gutters>
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-dark font-weight-bold font-primary">
          Invoice Management
        </h5>
      </b-col>
    </b-row>
    <b-row class="d-flex flex-row align-items-center justify-content-start w-100 card-header-row" no-gutters>
      <h6 class="mt-3">Default Invoice Settings</h6>
    </b-row>
    <!-- full row input (Old Password) -->
    <b-row class="d-flex flex-row align-items-start justify-content-start mt-2 w-100" no-gutters>
      <b-col cols="12" md="2">
        <span class="text-left text-dark font-primary font-weight-normal">Title</span>
      </b-col>
      <b-col cols="12" md="10">
        <!--        <FormInput-->
        <!--          groupId="user-name-group"-->
        <!--          id="user-name-group-input"-->
        <!--          class="text-prime-gray rounded-2"-->
        <!--          v-model="invoiceTitle"-->
        <!--          :state="invoiceTitleError.status"-->
        <!--          :invalidFeedback="`${invoiceTitleError.message}`"-->
        <!--          isRequired-->
        <!--          :whiteBG="false"-->
        <!--          :counter="80"-->
        <!--          trim-->
        <!--          form="login-form"-->
        <!--          type="text"-->
        <!--        ></FormInput>-->
        <b-form-input list="my-list-id" groupId="user-name-group" id="user-name-group-input"
          class="text-prime-gray rounded-2" v-model="invoiceTitle" :state="invoiceTitleError.status"
          :invalidFeedback="`${invoiceTitleError.message}`" isRequired :whiteBG="false" :counter="80" trim type="text"
          style="margin-bottom: 25px">
        </b-form-input>
        <datalist id="my-list-id">
          <option v-for="size in titleList" :key="size">{{ size }}</option>
        </datalist>
      </b-col>
      <!-- <b-col
        cols="3"
        class="d-flex flex-column align-items-center justify-content-start"
      >
        <b-row>
          <span
            class="text-left text-gray-750 font-primary font-weight-normal mr-2"
            >Set Default</span
          >
          <b-form-checkbox v-model="checked" name="check-button" switch>
          </b-form-checkbox>
        </b-row>
      </b-col> -->
    </b-row>
    <!-- full row input (Old Password) -->
    <b-row class="d-flex flex-row align-items-start justify-content-center w-100" no-gutters>
      <b-col cols="12" md="2">
        <span class="text-left text-dark font-primary font-weight-normal">Summary</span>
      </b-col>
      <b-col cols="12" md="10">
        <FormInput groupId="user-name-group" id="user-name-group-input" class="text-prime-gray rounded-2"
          v-model="invoiceSummary" :state="invoiceSummaryError.status"
          :invalidFeedback="`${invoiceSummaryError.message}`" isRequired :whiteBG="false" :counter="80" trim
          form="login-form" type="text"></FormInput>
      </b-col>
      <!-- <b-col
        cols="3"
        class="d-flex flex-column align-items-center justify-content-start"
      >
        <b-row>
          <span
            class="text-left text-gray-750 font-primary font-weight-normal mr-2"
            >Set Default</span
          >
          <b-form-checkbox v-model="checked" name="check-button" switch>
          </b-form-checkbox>
        </b-row>
      </b-col> -->
    </b-row>

    <b-row class="d-flex flex-row align-items-start justify-content-center w-100" no-gutters>
      <b-col cols="12" md="2">
        <span class="text-left text-dark font-primary font-weight-normal">GST/VAT Number</span>
      </b-col>
      <b-col cols="12" md="10">
        <FormInput groupId="gstvat-group" id="gstvat-group-input" class="text-prime-gray rounded-2" v-model="gst"
          :state="gstError.status" :invalidFeedback="`${gstError.message}`" :whiteBG="false" trim form="login-form"
          type="text"></FormInput>
      </b-col>
    </b-row>

    <!-- full row input (Old Password) -->
    <b-row class="d-flex flex-row align-items-center justify-content-start mt-4 w-100">
      <b-col cols="12" md="2">
        <span class="text-left text-dark font-primary font-weight-normal">Business</span>
      </b-col>
      <b-col cols="12" md="10">
        <b-row class="d-flex flex-row align-items-center justify-content-start" no-gutters>
          <span class="text-left text-dark font-primary font-weight-bold">
            {{ currentBusiness.name ? currentBusiness.name : "" }}
          </span>
        </b-row>
        <b-row class="d-flex flex-row align-items-end justify-content-start" no-gutters>
          <span class="text-left text-gray-750 font-primary font-weight-normal">{{
            currentBusiness.address ? currentBusiness.address.city : ""
          }}</span>
        </b-row>
        <b-row class="d-flex flex-row align-items-end justify-content-start" no-gutters>
          <span class="text-left text-gray-750 font-primary font-weight-normal">
            {{ currentBusiness.country ? currentBusiness.country : "" }}
          </span>
        </b-row>
        <b-row class="d-flex flex-row align-items-end justify-content-start mt-3" no-gutters>
          <b-link href="#" class="text-left text-main-green font-primary font-weight-normal"
            @click="openUpdateCurrentWorkspaceModal($event.target)" :disabled="getUserRole.role.includes('User')">Edit
            your business address and contact details</b-link>
        </b-row>
      </b-col>
      <!-- <b-col
        cols="3"
        class="d-flex flex-column align-items-center justify-content-start"
      >
        <b-row>
          <span
            class="text-left text-gray-750 font-primary font-weight-normal mr-2"
            >Set Default</span
          >
          <b-form-checkbox v-model="checked" name="check-button" switch>
          </b-form-checkbox>
        </b-row>
      </b-col> -->
    </b-row>
    <!-- full row input (image upload) -->
    <!-- <b-cols="12"
      md="6"
      class="d-inline-flex flex-row align-items-end justify-content-start mt-3 w-auto"
      no-gutters -->
    <b-row class="d-flex flex-row align-items-center justify-content-start mt-4 w-100">
      <!-- <b-col class="flex-grow-0 px-0"> -->
      <b-col cols="12" md="2">
        <span class="text-left text-dark font-primary font-weight-normal">Logo</span>
      </b-col>
      <b-col cols="12" md="10" class="d-flex flex-column align-items-start justify-content-center px-3 w-auto">

        <template v-if="imageUrl">
          <img class="d-block" :src="imageUrl" :width="280" :height="175" style="object-fit: contain" />
          <b-row class="d-flex flex-row align-items-center justify-content-center" no-gutters>
            <!-- remove button -->
            <b-button class="remove-button" @click="removeLinkFn" variant="outline-danger" block
              :disabled="getUserRole.role.includes('User')">Remove Image</b-button>
          </b-row>
        </template>
        <template v-else>
          <b-row>
            <b-col>
              <InvoiceImageUpload @getImage="(e) => (imageFile = e)" @getImageUrl="(e) => (imageUrl = e)"
                placeholder="Upload your associated media files here" />
            </b-col>
            <b-col class="align-items-center justify-content-center d-flex p-2 ">
              <b-icon icon="info-circle-fill" scale="1.5" class="info-circle" v-b-tooltip.hover id="button-2"></b-icon>
              <b-tooltip target="button-2" placement="right" variant="light">
                <p class="m-0">Logo guidelines</p>
                <ul>
                  <li style="font-size: 12px;">
                    <p class="p-0 m-0" style="text-align: left;">
                      Images must be less than 5MB in size.
                    </p>
                  </li>
                  <li style="font-size: 12px;">
                    <p class="p-0 m-0" style="text-align: left;">
                      Images must be at least 500x500px in size..
                    </p>
                  </li>
                  <li style="font-size: 12px;">
                    <p class="p-0 m-0" style="text-align: left;">
                      PNG and JPG is the preferred format..
                    </p>
                  </li>
                </ul>
              </b-tooltip>

            </b-col>
          </b-row>
        </template>

      </b-col>
      <!-- </b-col> -->
    </b-row>
    <!-- full row input (Old Password) -->
    <b-row class="d-flex flex-row align-items-center justify-content-start mt-4 w-100" no-gutters>
      <b-col cols="12" md="2">
        <span class="text-left text-dark font-primary font-weight-normal">Notes</span>
      </b-col>
      <b-col cols="12" md="10">
        <FormInput groupId="user-name-group" id="user-name-group-input" class="text-prime-gray rounded-2"
          v-model="invoiceNotes" :state="invoiceNotesError.status" :invalidFeedback="`${invoiceNotesError.message}`"
          isRequired :whiteBG="false" :counter="200" trim form="login-form" type="text" isTextArea></FormInput>
        <b-dropdown size="sm" text="Quick Notes" dropup class="text-prime-gray rounded-2" variant="outline-main-green">
          <b-dropdown-item @click="invoiceNotes = 'Insert invoice number when remitting funds.'">Insert invoice number
            when
            remitting funds.</b-dropdown-item>
          <b-dropdown-item @click="
            invoiceNotes = 'Our bank account details for remitting funds are:'
            ">Our bank account details for remitting funds are:</b-dropdown-item>
          <b-dropdown-item @click="invoiceNotes = 'Settle via Stripe today - it\'s easy and hassle free.'">Settle via
            Stripe
            today - it's easy and hassle free.</b-dropdown-item>
        </b-dropdown>
      </b-col>
      <!-- <b-col
        cols="3"
        class="d-flex flex-column align-items-center justify-content-start"
      >
        <b-row>
          <span
            class="text-left text-gray-750 font-primary font-weight-normal mr-2"
            >Set Default</span
          >
          <b-form-checkbox v-model="checked" name="check-button" switch>
          </b-form-checkbox>
        </b-row>
      </b-col> -->
    </b-row>
    <!-- full row input (Old Password) -->
    <b-row class="d-flex flex-row align-items-start justify-content-center w-100 mt-2" no-gutters>
      <b-col cols="12" md="2">
        <span class="text-left text-dark font-primary font-weight-normal">Footer</span>
      </b-col>
      <b-col cols="12" md="10">
        <FormInput groupId="user-name-group" id="user-name-group-input" class="text-prime-gray rounded-2"
          v-model="invoiceFooter" :state="invoiceFooterError.status" :invalidFeedback="`${invoiceFooterError.message}`"
          isRequired :whiteBG="false" :counter="200" trim form="login-form" type="text" isTextArea></FormInput>
        <b-dropdown size="sm" text="Quick Footers" dropup class="text-prime-gray rounded-2"
          variant="outline-main-green">
          <b-dropdown-item @click="invoiceFooter = 'Thank you for your business.'">Thank you for your
            business.</b-dropdown-item>
          <b-dropdown-item @click="invoiceFooter = 'We appreciate your valued business.'">We appreciate your valued
            business.</b-dropdown-item>
          <b-dropdown-item @click="
            invoiceFooter =
            'We strive to go the extra mile. We look forward to your prompt payment.'
            ">We strive to go the extra mile. We look forward to your prompt
            payment.</b-dropdown-item>
          <b-dropdown-item @click="invoiceFooter = 'Your business is important to us.'">Your business is important to
            us.</b-dropdown-item>
        </b-dropdown>
      </b-col>
      <!-- <b-col
        cols="3"
        class="d-flex flex-column align-items-center justify-content-start"
      >
        <b-row>
          <span
            class="text-left text-gray-750 font-primary font-weight-normal mr-2"
            >Set Default</span
          >
          <b-form-checkbox v-model="checked" name="check-button" switch>
          </b-form-checkbox>
        </b-row>
      </b-col> -->
    </b-row>
    <!-- form action buttons  -->
    <b-row class="d-flex flex-row align-items-center justify-content-center w-100 mx-0">
      <!-- <b-link
        @click="gobackFn()"
        class="text-left font-primary text-gray-750 font-weight-normal mr-4 load-more-btn"
        >Go back</b-link
      > -->
      <!-- <b-col cols="10" sm="6" md="4" lg="6">
        <b-button
          variant="dark-purple"
          pill
          class="border-0 px-5 py-2 w-100 add-question-btn"
          @click="updateInvoiceDefaultFn"
        >
          Save
        </b-button>
      </b-col> -->
    </b-row>
    <!-- form action buttons  -->
    <b-row class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end px-4 mb-4 w-100">
      <b-button variant="gray-550" pill class="my-2 my-sm-0 mr-0 mr-sm-2 border-0 bg-ghost-white action-btn"
        @click="cancel">
        Cancel
      </b-button>
      <b-button variant="main-green" pill class="my-2 my-sm-0 ml-0 ml-sm-2 border-0 action-btn-save"
        @click="updateInvoiceDefaultFn" :disabled="getUserRole.role.includes('User')">
        Save
      </b-button>
    </b-row>
    <!-- <b-row
      class="d-flex flex-row align-items-center justify-content-start px-3 w-100 card-header-row"
      no-gutters
    >
      <h5 class="py-2">
        Default Column Settings
      </h5>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 px-3 pt-4"
      no-gutters
    >
      <b-col cols="2">
        <span class="text-left text-gray-750 font-primary font-weight-normal"
          >Items</span
        >
      </b-col>
      <b-col cols="4">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="selectedItems"
            :options="itemsOptions"
            :aria-describedby="ariaDescribedby"
            name="plain-stacked"
            plain
            stacked
          ></b-form-radio-group>
        </b-form-group>
        <template v-if="selectedItems == 'other'">
          <b-form-input v-model="selectedItemsOther"></b-form-input>
        </template>
      </b-col>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 px-3 pt-4"
      no-gutters
    >
      <b-col cols="2">
        <span class="text-left text-gray-750 font-primary font-weight-normal"
          >Units</span
        >
      </b-col>
      <b-col cols="4">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="selectedUnits"
            :options="unitsOptions"
            :aria-describedby="ariaDescribedby"
            name="plain-stacked"
            plain
            stacked
          ></b-form-radio-group>
        </b-form-group>
        <template v-if="selectedUnits == 'other'">
          <b-form-input v-model="selectedUnitsOther"></b-form-input>
        </template>
      </b-col>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 px-3 pt-4"
      no-gutters
    >
      <b-col cols="2">
        <span class="text-left text-gray-750 font-primary font-weight-normal"
          >Price</span
        >
      </b-col>
      <b-col cols="4">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="selectedPrice"
            :options="priceOptions"
            :aria-describedby="ariaDescribedby"
            name="plain-stacked"
            plain
            stacked
          ></b-form-radio-group>
        </b-form-group>
        <template v-if="selectedPrice == 'other'">
          <b-form-input v-model="selectedPriceOther"></b-form-input>
        </template>
      </b-col>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 px-3 pt-4"
      no-gutters
    >
      <b-col cols="2">
        <span class="text-left text-gray-750 font-primary font-weight-normal"
          >Amount</span
        >
      </b-col>
      <b-col cols="4">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="selectedAmount"
            :options="amountOptions"
            :aria-describedby="ariaDescribedby"
            name="plain-stacked"
            plain
            stacked
          ></b-form-radio-group>
        </b-form-group>
        <template v-if="selectedAmount == 'other'">
          <b-form-input v-model="selectedAmountOther"></b-form-input>
        </template>
      </b-col>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-center justify-content-end justify-content-sm-end my-4 mr-4 w-100"
    >
      <b-button
        variant="secondary"
        pill
        class="my-2 my-sm-0 ml-0 ml-sm-2 border-0 action-btn"
        @click="updateInvoiceDefaultFn"
      >
        Save
      </b-button>
    </b-row> -->
    <UpdateCurrentWorkspaceModal :itemContent="myBusiness" @reloadFn="callInitFn" :key="showUpdateWorkspaceModalKey" />
  </b-row>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import FormInput from "@/components/Form/FormInput";
import InvoiceImageUpload from "@/components/ImageUpload/InvoiceImageUpload";
import UpdateCurrentWorkspaceModal from "@/components/Invoices/modals/UpdateCurrentWorkspaceModal";

// services
import {
  UpdateInvoiceDefaults,
  GetInvoiceDefaults,
  // UploadLogo,
  GetCurrentWorkspace,
} from "@/services/settingsbusiness.service";

export default {
  name: "InvoiceManagement",
  components: {
    FormInput,
    InvoiceImageUpload,
    UpdateCurrentWorkspaceModal,
  },
  data() {
    return {
      invoiceTitle: null,
      invoiceSummary: null,
      imageFile: null,
      imageUrl: null,
      invoiceNotes: null,
      invoiceFooter: null,
      myBusiness: {},
      currentBusiness: {},
      gst: null,
      showUpdateWorkspaceModalKey: Number(new Date()) + 100,
      invoiceTitleError: {
        status: null,
        message: "",
      },
      invoiceSummaryError: {
        status: null,
        message: "",
      },
      invoiceNotesError: {
        status: null,
        message: "",
      },
      invoiceFooterError: {
        status: null,
        message: "",
      },
      gstError: {
        status: null,
        message: "",
      },
      selectedItems: "items",
      itemsOptions: [
        {
          text: "Items",
          value: "items",
        },
        {
          text: "Products",
          value: "products",
        },
        {
          text: "Services",
          value: "services",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      selectedUnits: "quantity",
      unitsOptions: [
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Hours",
          value: "hours",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      selectedPrice: "price",
      priceOptions: [
        {
          text: "Price",
          value: "price",
        },
        {
          text: "Rate",
          value: "rate",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      selectedAmount: "amount",
      amountOptions: [
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      titleList: [
        "Pro Forma Invoice",
        "Credit Note",
        "Tax Invoice",
        "Void Invoice",
      ],
    };
  },
  watch: {
    getInvoiceDefaults(value) {
      console.log("invoice def", value);
    },
    invoiceTitle(val) {
      console.log(
        "🚀 ~ file: InvoiceManagement.vue:637 ~ invoiceTitle ~ val:",
        val
      );
      // if (val) {
      //   this.questionTitleError.status = true;
      // } else {
      //   this.questionTitleError.status = false;
      //   this.questionTitleError.message = "Please enter the question title";
      // }
      this.invoiceTitleError.status = null;
      if (val == "") {
        this.invoiceTitleError.status = false;
        this.invoiceTitleError.message = "This field is mandatory";
        return;
      }

      if (val.length <= 80) {
        this.invoiceTitleError.status = true;
      } else {
        this.invoiceTitleError.status = false;
        this.invoiceTitleError.message =
          "Title should have less than 80 characters";
      }
    },
    invoiceSummary(val) {
      // if (val) {
      //   this.questionTitleError.status = true;
      // } else {
      //   this.questionTitleError.status = false;
      //   this.questionTitleError.message = "Please enter the question title";
      // }
      this.invoiceSummaryError.status = null;
      if (val == "") {
        this.invoiceSummaryError.status = false;
        this.invoiceSummaryError.message = "This field is mandatory";
        return;
      }
      if (val.length <= 80) {
        this.invoiceSummaryError.status = true;
      } else {
        this.invoiceSummaryError.status = false;
        this.invoiceSummaryError.message =
          "Summary should have less than 80 characters";
      }
    },
    invoiceNotes(val) {
      // if (val) {
      //   this.questionTitleError.status = true;
      // } else {
      //   this.questionTitleError.status = false;
      //   this.questionTitleError.message = "Please enter the question title";
      // }
      this.invoiceNotesError.status = null;
      if (val == "") {
        this.invoiceNotesError.status = false;
        this.invoiceNotesError.message = "This field is mandatory";
        return;
      }
      if (val.length <= 200) {
        this.invoiceNotesError.status = true;
      } else {
        this.invoiceNotesError.status = false;
        this.invoiceNotesError.message =
          "Notes should have less than 200 characters";
      }
    },
    invoiceFooter(val) {
      // if (val) {
      //   this.questionTitleError.status = true;
      // } else {
      //   this.questionTitleError.status = false;
      //   this.questionTitleError.message = "Please enter the question title";
      // }
      this.invoiceFooterError.status = null;
      if (val == "") {
        this.invoiceFooterError.status = false;
        this.invoiceFooterError.message = "This field is mandatory";
        return;
      }
      if (val.length <= 200) {
        this.invoiceFooterError.status = true;
      } else {
        this.invoiceFooterError.status = false;
        this.invoiceFooterError.message =
          "Footer should have less than 200 characters";
      }
    },
  },
  computed: {
    ...mapGetters({
      getEmail: "auth/getEmail",
      getInvoiceDefaults: "invoiceCreate/getInvoiceDefaults",
      // getCurrentWorkspace: "auth/getCurrentWorkspace",
      getUserRole: "auth/getUserRole",
    }),
    computedTitleValidation() {
      if (this.invoiceTitle) {
        if (this.invoiceTitle.length <= 80) {
          return true;
        }
        return false;
      } else return false;
    },
    computedSummaryValidation() {
      if (this.invoiceSummary) {
        if (this.invoiceSummary.length <= 80) {
          return true;
        }
        return false;
      } else return false;
    },
    computedNotesValidation() {
      if (this.invoiceNotes) {
        if (this.invoiceNotes.length <= 200) {
          return true;
        }
        return false;
      } else return false;
    },
    computedFooterValidation() {
      if (this.invoiceFooter) {
        if (this.invoiceFooter.length <= 200) {
          return true;
        }
        return false;
      } else return false;
    },
  },
  async mounted() {
    await this.initFn();
    await this.initCurrentWS();
    this.isEditProduct = this.$route.meta.isEdit;
    if (this.isEditProduct) {
      this.initEditProductFn(this.$route.params.productId);
      // console.log(this.$route.params.invoiceId);
    }
  },
  methods: {
    removeLinkFn() {
      this.imageUrl = null;
      this.imageFile = null;
    },
    async initFn() {
      try {
        let { data } = await GetInvoiceDefaults();
        console.log(
          "🚀 ~ file: InvoiceManagement.vue:740 ~ initFn ~ data:",
          data
        );
        console.log("get inv def", data);
        // invoice defaults
        this.invoiceTitle = data[0] ? data[0].title : null;
        this.invoiceSummary = data[0] ? data[0].summary : null;
        this.imageUrl = data[0] ? data[0].logo : null;
        this.invoiceNotes = data[0] ? data[0].notes : null;
        this.invoiceFooter = data[0] ? data[0].footer : null;
        this.gst = data[0] ? data[0].gst : null
        // business details
        this.myBusiness = data;
        console.log(
          "🚀 ~ file: InvoiceManagement.vue:791 ~ initFn ~ myBusiness:",
          this.myBusiness
        );
      } catch (error) {
        console.log(error);
      }
    },
    async initCurrentWS() {
      let { data } = await GetCurrentWorkspace();
      this.currentBusiness = data;
      if (this.gst === null) {
        this.gst = data.gst ? data.gst : null;
      }
      console.log(
        "🚀 ~ file: InvoiceManagement.vue:802 ~ initCurrentWS ~ this.currentBusiness:",
        this.currentBusiness
      );
      console.log("currentBusiness", this.currentBusiness);
    },
    openUpdateCurrentWorkspaceModal(button) {
      console.log("clicked", button);
      this.$root.$emit("bv::show::modal", `update-workspace-modal`, button);
      console.log("my business", this.myBusiness);
    },
    async updateInvoiceDefaultFn() {
      console.log("invoice default image", this.imageFile);
      // if (
      //   this.computedTitleValidation &&
      //   this.computedSummaryValidation &&
      //   this.computedNotesValidation &&
      //   this.computedFooterValidation
      // ) {
      let payload = {
        title: this.invoiceTitle,
        summary: this.invoiceSummary,
        notes: this.invoiceNotes,
        footer: this.invoiceFooter,
        logo: this.imageUrl,
        gst: this.gst
      };
      try {
        let { data, status } = await UpdateInvoiceDefaults(payload);
        console.log("invoice default updated", data);
        // let { image } = await UploadLogo(this.imageFile);
        // console.log("invoice default image", image);
        if (status == 200) {
          this.$store.dispatch("invoiceCreate/setInvoiceDefaults", payload);
          let payloadNotify = {
            isToast: true,
            title: "Default Invoice Settings Update",
            content:
              "The default invoice settings have been updated successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        console.log(error);
      }
      // try {
      //   const file = this.imageFile;
      //   let formData = new FormData();
      //   formData.append("file", file);
      //   console.log("image file formData", formData);
      //   const result = await UploadLogo(formData);
      //   console.log("invoice default image", result);
      // } catch (error) {
      //   console.log(error);
      // }
      // } else {
      //   this.errorFn();
      // }
    },
    errorFn() {
      if (!this.invoiceTitle) {
        this.invoiceTitleError.status = false;
        this.invoiceTitleError.message = "Please fill in the title";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to save Default Invoice",
          content: "Please enter the Default Invoice title",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } else if (this.invoiceTitle.length > 80) {
        this.invoiceTitleError.status = false;
        this.invoiceTitleError.message =
          "Title should have less than 80 characters";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to save Default Invoice",
          content: "Title should have less than 80 characters",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (this.invoiceSummary && this.invoiceSummary.length > 80) {
        this.invoiceSummaryError.status = false;
        this.invoiceSummaryError.message =
          "Summary should have less than 80 characters";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to save Default Invoice",
          content: "Summary should have less than 80 characters",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (this.invoiceNotes && this.invoiceNotes.length > 200) {
        this.invoiceNotesError.status = false;
        this.invoiceNotesError.message =
          "Notes should have less than 200 characters";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to save Default Invoice",
          content: "Notes should have less than 200 characters",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
      if (this.invoiceFooter && this.invoiceFooter.length > 200) {
        this.invoiceFooterError.status = false;
        this.invoiceFooterError.message =
          "Footer should have less than 200 characters";
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to save Default Invoice",
          content: "Footer should have less than 200 characters",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    async callInitFn() {
      // this.showDeleteTransactionModalKey = Number(new Date()) + 100;
      this.showUpdateWorkspaceModalKey = Number(new Date()) + 100;
      await this.initFn();
      await this.initCurrentWS();
      console.log("call init fn");
    },
  },
};
</script>

<style scoped>
.info-circle {
  /* background: #828282; */
  color: #828282;
}

.main-text {
  font-size: 1.2rem;
}

.sub-text {
  font-size: 1rem;
}

.add-question-btn:hover {
  background: var(--main-green);
  box-shadow: inset 0px 0px 10px #ffffff;
}

.remove-button {
  display: inline-block;
  margin-top: 10px;
  width: 280px;
}

.action-btn {
  height: 50px;
  min-width: 120px;
}

.action-btn-save {
  height: 50px;
  min-width: 120px;
}

.action-btn-save:hover {
  background: var(--main-green);
  box-shadow: inset 0px 0px 10px #ffffff;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .action-btn {
    width: 80%;
  }
}
</style>
