<template>
  <b-row class="d-flex flex-row align-items-center justify-content-between w-100" no-gutters>
    <!-- Contact Title row  -->
    <b-row class="d-flex flex-row align-items-start justify-content-start" no-gutters>
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-dark font-weight-bold font-primary">
          {{ this.getBusinessId ? "Update a Business" : "Create a Business" }}
        </h5>
      </b-col>
    </b-row>
    <!-- full row input (Customer) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pt-4 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">
          Individual/Business Name
          <span class="star-mark-color"> * </span>
        </span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="business-name-group" id="business-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          :state="businessnameError.status" :invalidFeedback="`${businessnameError.message}`"
           v-model="businessname" isRequired :whiteBG="false" trim form="login-form"
          type="text"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Customer) IRD/TIN Number -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">
          IRD/TIN Number
        </span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="business-name-group" id="business-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="ird" isRequired :whiteBG="false" trim form="login-form" type="text"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Customer) BIC Code -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">
          BIC Code
        </span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="business-name-group" id="business-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="bic" isRequired :whiteBG="false" trim form="login-form" type="text"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Customer) Business is about -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2 mt-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Business is about
          <!-- <span class="star-mark-color"> * </span> -->
        </span>
      </b-col>
      <b-col cols="8">
        <!-- <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          variant="light"
          class="w-100 py-0 m-0"
          toggle-class="border border-dark-subtle bg-white rounded-lg "
          menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
          left
          no-caret
          no-flip
          text="Button text via Prop"
        >
          <template v-slot:button-content>
            <b-button
              block
              id="customer-modal-btn"
              variant="transparent"
              class="w-100 d-flex  align-items-center justify-content-end
               rounded-lg "
            >
              <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
            </b-button>
          </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
        <FormSelect groupId="industry-group" id="industry-group-input" class="text-prime-gray rounded-2"
           :state="businessIndustryError.status"
          :invalidFeedback="`${businessIndustryError.message}`" v-model="businessIndustrytype" :whiteBG="false"
          :options="businessIndustryOptions" form="login-form"></FormSelect>
      </b-col>
    </b-row>
    <!-- full row input (Phone) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Type Of Business
          <!-- <span class="star-mark-color"> * </span> -->
        </span>
      </b-col>
      <b-col cols="8">
        <!-- <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          variant="light"
          class="w-100 py-0 "
          toggle-class="border border-dark-subtle bg-white rounded-lg "
          menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
          left
          no-caret
          no-flip
          text="Button text via Prop"
        >
          <template v-slot:button-content>
            <b-button
              block
              id="customer-modal-btn"
              variant="transparent"
              class="w-100 d-flex  align-items-center justify-content-end
               rounded-lg "
            >
              <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
            </b-button>
          </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
        <FormSelect groupId="industry-group" id="industry-group-input" class="text-prime-gray rounded-2 mt-2"
          :state="business_typeError.status"
          :invalidFeedback="`${business_typeError.message}`" v-model="business_type" :whiteBG="false"
          :options="businessTypeOptions" form="login-form"></FormSelect>
      </b-col>
    </b-row>
    <!-- full row input (Phone) Currently in business -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Currently in business?
        </span>
      </b-col>
      <b-col cols="8">
        <!-- <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          variant="light"
          class="w-100 py-0"
          toggle-class="border border-dark-subtle bg-white rounded-lg "
          menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
          left
          no-caret
          no-flip
          text="Button text via Prop"
        >
          <template v-slot:button-content>
            <b-button
              block
              id="customer-modal-btn"
              variant="transparent"
              class="w-100 d-flex  align-items-center justify-content-end
               rounded-lg "
            >
              <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
            </b-button>
          </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
        <FormSelect groupId="business-description-group" id="business-description-group-input"
          class="text-prime-gray rounded-2 mt-2"
          :state="businessDescriptionError.status" :invalidFeedback="`${businessDescriptionError.message}`"
          v-model="buisness_status" :whiteBG="false" :options="businessDescriptionOptions" form="login-form"></FormSelect>
      </b-col>
    </b-row>
    <!-- full row input (Phone) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Business Country
          <span class="star-mark-color"> * </span>
        </span>
      </b-col>
      <b-col cols="8">
        <FormSelect groupId="industry-group" id="industry-group-input" class="text-prime-gray rounded-2 mt-2"
          :state="businessCountryError.status"
          :invalidFeedback="`${businessCountryError.message}`" v-model="businessCountry" :whiteBG="false"
          :options="countryList" form="login-form"></FormSelect>
        <!-- <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          variant="light"
          class="w-100 py-0"
          toggle-class="border border-dark-subtle bg-white rounded-lg "
          menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
          left
          no-caret
          no-flip
          text="Button text via Prop"
        >
          <template v-slot:button-content>
            <b-button
              block
              id="customer-modal-btn"
              variant="transparent"
              class="w-100 d-flex  align-items-center justify-content-end
               rounded-lg "
            >
              <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
            </b-button>
          </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
      </b-col>
    </b-row>
    <!-- full row input (Customer) GST/VAT Registration -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">
          GST/VAT Registration
        </span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="business-name-group" id="business-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="gst" isRequired :whiteBG="false" trim form="login-form" type="text"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Customer) NZBN -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">
          NZBN
        </span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="business-name-group" id="business-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="nzbn" isRequired :whiteBG="false" trim form="login-form" type="text"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Customer) No. of Customers-->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">No. of Customers
        </span>
      </b-col>
      <b-col cols="8">
        <!-- <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          variant="light"
          class="w-100 py-0"
          toggle-class="border border-dark-subtle bg-white rounded-lg "
          menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
          left
          no-caret
          no-flip
          text="Button text via Prop"
        >
          <template v-slot:button-content>
            <b-button
              block
              id="customer-modal-btn"
              variant="transparent"
              class="w-100 d-flex  align-items-center justify-content-end
               rounded-lg"
            >
              <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
            </b-button>
          </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
        <FormSelect groupId="no-of-customers-group" id="no-of-customers-group-input"
          class="text-prime-gray rounded-2 mt-2" :state="customers_rangeError.status"
          :invalidFeedback="`${customers_rangeError.message}`" v-model="customers_range"
          :whiteBG="false" :options="numOfCustomersOptions" form="login-form"></FormSelect>
      </b-col>
    </b-row>
    <!-- full row input (Customer) Accept payments online ?-->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Accept payments online ?
        </span>
      </b-col>
      <b-col cols="8">
        <FormSelect groupId="payments-online-group" id="payments-online-group-input"
          class="text-prime-gray rounded-2 mt-2" :state="online_paymentsError.status"
          :invalidFeedback="`${online_paymentsError.message}`" v-model="online_payments"
          :whiteBG="false" :options="paymentsOnlineOptions" form="login-form"></FormSelect>
      </b-col>
    </b-row>
    <!-- full row input (Customer) Currently accounts manage  by?-->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Currently accounts manage by?
        </span>
      </b-col>
      <b-col cols="8">
        <!-- <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          variant="light"
          class="w-100 py-0"
          toggle-class="border border-dark-subtle bg-white rounded-lg "
          menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
          left
          no-caret
          no-flip
          text="Button text via Prop"
        >
          <template v-slot:button-content>
            <b-button
              block
              id="customer-modal-btn"
              variant="transparent"
              class="w-100 d-flex  align-items-center justify-content-end
               rounded-lg "
            >
              <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
            </b-button>
          </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
        <FormSelect groupId="manage-accounts-group" id="manage-accounts-group-input"
          class="text-prime-gray rounded-2 mt-2" :state="current_managing_methodError.status"
          :invalidFeedback="`${current_managing_methodError.message}`"
           v-model="current_managing_method" :whiteBG="false"
          :options="manageAccountsOptions" form="login-form"></FormSelect>
      </b-col>
    </b-row>
    <!--=======================================================================================  -->
    <!-- Contact Information -->
    <b-row class="d-flex flex-row align-items-start justify-content-start pt-4" no-gutters>
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-gray-750 font-weight-normal font-primary">
          Contact
        </h5>
      </b-col>
    </b-row>
    <!-- full row input (No :) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pt-4 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Phone</span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="user-name-group" id="user-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="customerPhone" :state="customerPhoneError.status" :invalidFeedback="customerPhoneError.message"
          @keypress="isTelephoneInput($event)" isRequired :whiteBG="false" trim form="login-form" type="tel"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (No :) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Mobile</span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="user-name-group" id="user-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="customerMobile" :state="customerMobileError.status" :invalidFeedback="customerMobileError.message"
          isRequired @keypress="isTelephoneInput($event)" :whiteBG="false" trim form="login-form" type="tel"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (No :) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Email</span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="user-name-group" id="user-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="customerEmail" :state="customerEmailError.status" :invalidFeedback="customerEmailError.message"
          isRequired :whiteBG="false" trim form="login-form" type="text"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (No :) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Website(URL)</span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="user-name-group" id="user-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="customerWebsite" :whiteBG="false" trim form="login-form" type="text"></FormInput>
        <!-- :state="customerWebsiteError.status"
          :invalidFeedback="customerWebsiteError.message"
          isRequired -->
      </b-col>
    </b-row>
    <!--=======================================================================================  -->
    <!-- Billing Address Title row  -->
    <b-row class="d-flex flex-row align-items-start justify-content-start pt-4" no-gutters>
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5 class="text-left text-gray-750 font-weight-normal font-primary">
          Address
        </h5>
      </b-col>
    </b-row>
    <!-- full row input (No :) -->
    <b-row
      class="d-flex flex-row align-items-center justify-content-end w-100 pt-2 pb-2"
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal"
          >Country</span
        >
      </b-col>
      <b-col cols="8">
        <FormSelect
          groupId="industry-group"
          id="industry-group-input"
          class="text-prime-gray rounded-2 m-0 p-0"
          :state="addressCountryError.status"
          :invalidFeedback="`${addressCountryError.message}`"
          v-model="addressCountry"
          :whiteBG="false"
          :options="countryList"
          form="login-form"
        ></FormSelect>
      </b-col>
    </b-row>
    <!-- full row input (No :) -->
    <b-row
      class="d-flex flex-row align-items-center justify-content-end w-100 pt-4 pb-2"
      no-gutters
    >
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">No :</span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="user-name-group" id="user-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="addressNum" :state="addressNumError.status" :invalidFeedback="addressNumError.message" isRequired
          :whiteBG="false" trim form="login-form" type="text"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Street) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Street</span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="user-name-group" id="user-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="addressStreet" :state="addressStreetError.status" :invalidFeedback="addressStreetError.message"
          isRequired :whiteBG="false" trim form="login-form" type="text"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (City) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">City</span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="user-name-group" id="user-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="addressCity" :state="addressCityError.status" :invalidFeedback="addressCityError.message" isRequired
          :whiteBG="false" trim form="login-form" type="text"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Country) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Province</span>
      </b-col>
      <b-col cols="8">
        <!-- <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          variant="light"
          class="w-100 m-0 py-0"
          toggle-class="border border-dark-subtle bg-white rounded-lg "
          menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
          left
          no-caret
          no-flip
          text="Button text via Prop"
        >
          <template v-slot:button-content>
            <b-button
              block
              id="customer-modal-btn"
              variant="transparent"
              class="w-100 d-flex  align-items-center justify-content-end
               rounded-lg p-1 m-0"
            >
              <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
            </b-button>
          </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
        <FormSelect
          groupId="fiscalyear-group"
          id="fiscalyear-group-input"
          class="text-prime-gray rounded-2 m-0 p-0"
          v-model="addressProvince"
          :state="addressProvinceError.status"
          :invalidFeedback="addressProvinceError.message"
          :whiteBG="false"
          :options="provinceList"
          form="login-form"
        ></FormSelect>
      </b-col>
    </b-row>
    <!-- full row input (Zip Code) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Zip Code</span>
      </b-col>
      <b-col cols="8">
        <FormInput groupId="user-name-group" id="user-name-group-input" class="text-prime-gray rounded-2 m-0 p-0"
          v-model="addressZip" :state="addressZipError.status" :invalidFeedback="addressZipError.message" isRequired
          :whiteBG="false" trim form="login-form" type="number"></FormInput>
      </b-col>
    </b-row>
    <!-- full row input (Province) -->
   
    <!-- ================================================================================================================== -->
    <!-- full row input (Phone) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Currency
          <span class="star-mark-color"> * </span>
        </span>
      </b-col>
      <b-col cols="8">
        <!-- <FormInput
          groupId="currency-group"
          id="currency-group-input"
          class="text-prime-gray rounded-2"
          :state="currencyError.status"
          :invalidFeedback="`${currencyError.message}`"
          label="Sri Lankan Rupees"
          v-model="currency"
          isRequired
          :whiteBG="false"
          :isDisabled="true"
          trim
          form="login-form"
          type="text"
        ></FormInput> -->
        <FormSelect groupId="currency-group" id="currency-group-input" class="text-prime-gray rounded-2"
          :state="currencyError.status" :invalidFeedback="`${currencyError.message}`"
          v-model="currency" isRequired :whiteBG="false" :options="currencyOptions" form="login-form"></FormSelect>
        <!-- <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          variant="light"
          class="w-100 m-0 py-0"
          toggle-class="border border-dark-subtle bg-white rounded-lg "
          menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
          left
          no-caret
          no-flip
          text="Button text via Prop"
        >
          <template v-slot:button-content>
            <b-button
              block
              id="customer-modal-btn"
              variant="transparent"
              class="w-100 d-flex  align-items-center justify-content-between
               rounded-lg p-0 m-0"
            >
              <span>NSD</span>
              <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
            </b-button>
          </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
      </b-col>
    </b-row>
    <!-- full row input (Phone) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Language
          <span class="star-mark-color"> * </span>
        </span>
      </b-col>
      <b-col cols="8">
        <FormSelect groupId="no-of-customers-group" id="no-of-customers-group-input"
          class="text-prime-gray rounded-2 mt-2" :state="languageError.status"
          :invalidFeedback="`${languageError.message}`" v-model="language" :whiteBG="false" :options="languageOptions"
          form="login-form"></FormSelect>
        <!-- <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          variant="light"
          class="w-100 m-0 py-0"
          toggle-class="border border-dark-subtle bg-white rounded-lg "
          menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
          left
          no-caret
          no-flip
          text="Button text via Prop"
        >
          <template v-slot:button-content>
            <b-button
              block
              id="customer-modal-btn"
              variant="transparent"
              class="w-100 d-flex  align-items-center justify-content-between
               rounded-lg p-0 m-0"
            >
              <span>English</span>
              <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
            </b-button>
          </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
        <!-- <FormSelect
          groupId="language-group"
          id="language-group-input"
          class="text-prime-gray rounded-2"
          :state="languageError.status"
          :invalidFeedback="`${languageError.message}`"
          label="Choose a language"
          v-model="language"
          isRequired
          :whiteBG="false"
          :options="languageOptions"
          form="login-form"
        ></FormSelect> -->
      </b-col>
    </b-row>
    <!-- full row input (Phone) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Time Zone
          <span class="star-mark-color"> * </span>
        </span>
      </b-col>
      <b-col cols="8">
        <!-- <FormInput
          groupId="timezone-group"
          id="timezone-group-input"
          class="text-prime-gray rounded-2"
          :state="timezoneError.status"
          :invalidFeedback="`${timezoneError.message}`"
          label="GMT 5.00 (Sri Lanaka Time)"
          v-model="timezone"
          isRequired
          :whiteBG="false"
          :isDisabled="true"
          trim
          form="login-form"
          type="text"
        ></FormInput> -->
        <FormSelect groupId="timezone-group" id="timezone-group-input" class="text-prime-gray rounded-2"
          :state="time_zoneError.status" :invalidFeedback="`${time_zoneError.message}`" label="Greenwich Mean Time (GMT)"
          v-model="time_zone" isRequired :whiteBG="false" :options="filteredTimezoneList" form="login-form"></FormSelect>
        <!-- <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          variant="light"
          class="w-100 m-0 py-0"
          toggle-class="border border-dark-subtle bg-white rounded-lg "
          menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
          left
          no-caret
          no-flip
          text="Button text via Prop"
        >
          <template v-slot:button-content>
            <b-button
              block
              id="customer-modal-btn"
              variant="transparent"
              class="w-100 d-flex  align-items-center justify-content-between
               rounded-lg p-0 m-0"
            >
              <span></span>
              <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
            </b-button>
          </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
      </b-col>
    </b-row>
    <!-- full row input (Phone) -->
    <b-row class="d-flex flex-row align-items-center justify-content-end w-100 pb-2" no-gutters>
      <b-col cols="4">
        <span class="text-left text-dark font-primary font-weight-normal">Fiscal Year
          <span class="star-mark-color"> * </span>
        </span>
      </b-col>
      <b-col cols="8">
        <FormSelect groupId="fiscalyear-group" id="fiscalyear-group-input" class="text-prime-gray rounded-2"
          :state="fiscalyearError.status" :invalidFeedback="`${fiscalyearError.message}`" label="January - December"
          v-model="fiscalyear" :whiteBG="false" :options="fiscalyearOptions" form="login-form"></FormSelect>
        <!-- <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          variant="light"
          class="w-100 m-0 py-0"
          toggle-class="border border-dark-subtle bg-white rounded-lg "
          menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
          left
          no-caret
          no-flip
          text="Button text via Prop"
        >
          <template v-slot:button-content>
            <b-button
              block
              id="customer-modal-btn"
              variant="transparent"
              class="w-100 d-flex  align-items-center justify-content-between
               rounded-lg p-0 m-0"
            >
              <span></span>
              <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
            </b-button>
          </template>
          <b-dropdown-item href="#">An item</b-dropdown-item>
          <b-dropdown-item href="#">Another item</b-dropdown-item>
        </b-dropdown> -->
      </b-col>
    </b-row>

    <!-- Billing Address Title row  -->

    <!-- form action buttons  -->
    <b-row class="d-flex flex-row align-items-center justify-content-end justify-content-sm-end my-4 w-100">
      <b-link @click="gobackFn()" class="text-left font-primary text-gray-750 font-weight-normal mr-4 load-more-btn">Go
        back</b-link>
      <b-button variant="main-green" pill class="my-2 my-sm-0 ml-0 ml-sm-2 border-0 action-btn" @click="businessCreateFn">
        Save
      </b-button>
    </b-row>

    <!-- getBusinessId -->
    <b-row v-if="getBusinessId" no-gutters>
      <hr class="bg-light w-100 mb-4" />
      <b-row class="d-flex flex-row align-items-start justify-content-start mb-4" no-gutters>
        <b-col class="d-flex flex-column align-items-start justify-content-start">
          <h5 class="text-left text-dark font-weight-bold font-primary">
            Deactivate This Business
          </h5>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row align-items-start justify-content-start" no-gutters>
        <b-col class="d-flex flex-column align-items-start justify-content-start">
          <p>
            This will hide <strong>Personal Account</strong> from every menu and
            you will no longer be able to access it. You can always restore this
            business later by contacting info@figa.co.nz
          </p>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row align-items-start justify-content-start" no-gutters>
        <b-col col>
          <b-button variant="outline-primary"
            class="d-flex flex-row align-items-center justify-content-center deactivate_btn my-2 my-lg-0 border rounded-lg border-primary"
            pill>
            <span class="d-inline ml-2"> Deactivate </span>
          </b-button>
        </b-col>
      </b-row>
    </b-row>
  </b-row>
</template>
<script>
// @ is an alias to /src
import FormInput from "@/components/Form/FormInput";
// import FormSelect from "@/components/Form/FormSelect";
import FormSelect from "@/components/Form/FormSelect";
import { GetPackageDetails, UpdateSingleWorkspace } from "@/services/settingsbusiness.service";
import { mapActions, mapGetters } from "vuex";

import CheckEmail from "@/util/CheckEmail";

import isValidContactNumber from "@/util/isValidContactNumber";

import isTelephoneInput from "@/mixin/isTelephoneInput.js";

// services
import {
  GetSingleWorkspace,
  GetCurrentWorkspace,
  createBusiness,
} from "@/services/settingsbusiness.service";
import { GetUserProfile } from "@/services/settings.service";
export default {
  name: "BusinessCreate",
  mixins: [isTelephoneInput],
  components: {
    FormInput,
    // FormSelect,
    FormSelect,
  },
  props: {
    itemContent: {
      type: Object,
    },
  },
  data() {
    return {
      provinceList:[],
      currentBusinessId: null,
      businessname: null,
      ird: null,
      bic: null,
      gst: null,
      businessIndustrytype: null,
      business_type: null,
      buisness_status: null,
      businessCountry: null,
      businessMax: null,
      businessCount: null,
      businessIndustryOptions: [
        "Art, Photography & Creative Services",
        "Construction & Home Improvement",
        "Consulting & Professional Services",
        "Financial Services & Insurance",
        "Hair, Spa & Aesthetics",
        "Medical, Dental & Health",
        "Non-profits, Associations & Groups",
        "Real Estate",
        "Retailers, Resellers & Sales",
        "Web, Tech & Media",
        "Travel & Tourism",
        "Other: I make or sell a product",
        "Other: I provide a service",
      ],
      businessTypeOptions: [
        "Sole Trader",
        "Individual Person",
        "Limited Company",
        "Limited",
        "Partner",
        "Accountant or Bookkeeper",
        "Charitable Trust",
        "Incorporated Society",
        "Trust",
        "Club",
        "Contractor",
        "Full-time Employee",
        "Part-time Employee",
      ],
      businessDescriptionOptions: [
        "Yeah, i m running it part-time",
        "Yeah, i m running it full-time",
        "Not yet, will launch it soon",
      ],

      countryList: ["New Zealand", "Australia", "Fiji"],
      currency: null,
      currencyOptions: ["NZD", "FJD", "AUD", "USD"],
      languageOptions: ["English"],
      time_zone: null,
      newzealandTimeoneOption: ["New Zealand Standard Time GMT+12"],
      australiaTimeoneOption: [
        "Australian Eastern Standard Time (AEST) GMT+10",
        "Australian Central Standard Time (ACST) GMT + 9.5",
        "Australian Western Standard Time (AWST) GMT +8",
      ],
      fijiTimeoneOption: ["Fiji Standard Time (GMT +12)"],
      fijiProvinceList: [
        "Ba",
        "Bua",
        "Cakaudrove",
        "Kadavu",
        "Lau",
        "Lomaiviti",
        "Macuata",
        "Nadroga-Navosa",
        "Naitasiri",
        "Namosi",
        "Ra",
        "Rewa",
        "Serua",
        "Tailevu",
      ],
      fiscalyear: null,
      fiscalyearOptions: ["January - December", "April - March", "July - June"],
      customers_range: null,
      numOfCustomersOptions: ["1-5", "5-10", "10 -20", "20+"],
      paymentsOnlineOptions: [
        "No, I don't",
        "Not yet, but I want to start",
        "Yes, I accept payments online",
      ],
      manageAccountsOptions: [
        "I use other accounting software",
        "I haven't started yet",
        "I do it manually (e.g. spreadsheets)",
      ],
      current_managing_method: null,
      online_payments: null,
      addressNum: null,
      addressStreet: null,
      addressCity: null,
      addressZip: null,
      addressProvince: null,
      addressCountry: null,
      customerPhone: null,
      customerMobile: null,
      customerEmail: null,
      customerWebsite: null,
      isEditPage: false,
      productNameError: {
        status: null,
        message: "",
      },
      NewzelandProvinceList: [
        "Northland",
        "Auckland",
        "Waikato",
        "Bay of Plenty",
        "Gisborne",
        "Hawke's Bay",
        "Taranaki",
        "Manawatu-Whanganui",
        "Wellington",
        "Tasman",
        "Nelson",
        "Marlborough",
        "West Coast",
        "Canterbury",
        "Otago",
        "Southland",
      ],
      AusiProvinceList: [
        "New South Wales",
        "Queensland",
        "Northern Territory",
        "Western Australia",
        "South Australia",
        "Victoria",
        "Australian Capital Territory",
        "Tasmania",
      ],
      language: "English",
      nzbn: "",
      address: {
        no: null,
        street: null,
        city: null,
        province: null,
        zip: null,
        country: "",
      },
      addressCountryError:{
        status: null,
        message: "",
      },
      currencyError: {
        status: null,
        message: "",
      },
      languageError: {
        status: null,
        message: "",
      },
      time_zoneError: {
        status: null,
        message: "",
      },
      fiscalyearError: {
        status: null,
        message: "",
      },
      businessnameError: {
        status: null,
        message: "",
      },
      irdError: {
        status: null,
        message: "",
      },
      bicError: {
        status: null,
        message: "",
      },
      gstError: {
        status: null,
        message: "",
      },
      nzbnError: {
        status: null,
        message: "",
      },
      businessIndustryError: {
        status: null,
        message: "",
      },
      business_typeError: {
        status: null,
        message: "",
      },
      businessDescriptionError: {
        status: null,
        message: "",
      },
      businessCountryError: {
        status: null,
        message: "",
      },
      addressNumError: {
        status: null,
        message: "",
      },
      addressStreetError: {
        status: null,
        message: "",
      },
      addressCityError: {
        status: null,
        message: "",
      },
      addressZipError: {
        status: null,
        message: "",
      },
      addressProvinceError: {
        status: null,
        message: "",
      },
      customerPhoneError: {
        status: null,
        message: "",
      },
      customerMobileError: {
        status: null,
        message: "",
      },
      customerEmailError: {
        status: null,
        message: "",
      },
      customerWebsiteError: {
        status: null,
        message: "",
      },
      customers_rangeError: {
        status: null,
        message: "",
      },
      online_paymentsError: {
        status: null,
        message: "",
      },
      current_managing_methodError: {
        status: null,
        message: "",
      },
    };
  },
  watch: {
    itemContent(val) {
      console.log("item content watch", val.address.number);
      this.fullName = val.name;
      this.addressNum = val.address.number;
      this.addressStreet = val.address.street;
      this.addressCity = val.address.city;
      this.addressZip = val.address.zipcode;
      this.addressProvince = val.address.province;

    },
    // productName(value) {
    //   if (value) {
    //     this.productNameError.status = true;
    //   } else {
    //     this.productNameError.status = false;
    //     this.productNameError.message = "Please fill out this field";
    //   }
    // },
    businessname(value) {
      if (value) {
        this.businessnameError.status = true;
      } else {
        this.businessnameError.status = false;
        this.businessnameError.message = "Please fill out this field";
      }
    },
    businessIndustry(value) {
      if (value) {
        this.businessIndustryError.status = true;
      } else {
        this.businessIndustryError.status = false;
        this.businessIndustryError.message = "Please fill out this field";
      }
    },
    businessCountry(value) {
      if (value) {
        this.businessCountryError.status = true;
        this.businessCountry = value;
        if(this.customerPhone)this.validatePhoneNumber(this.customerPhone, value);
        if(this.customerMobile) this.validateMobileNumber(this.customerMobile, value);
      } else {
        this.businessCountryError.status = false;
        this.businessCountryError.message = "Please fill out this field";
      }
    },
    currency(value) {
      if (value) {
        this.currencyError.status = true;
      } else {
        this.currencyError.status = false;
        this.currencyError.message = "Please fill out this field";
      }
    },
    language(value) {
      if (value) {
        this.languageError.status = true;
      } else {
        this.languageError.status = false;
        this.languageError.message = "Please fill out this field";
      }
    },
    timezone(value) {
      if (value) {
        this.time_zoneError.status = true;
      } else {
        this.time_zoneError.status = false;
        this.time_zoneError.message = "Please fill out this field";
      }
    },
    fiscalyear(value) {
      if (value) {
        this.fiscalyearError.status = true;
      } else {
        this.fiscalyearError.status = false;
        this.fiscalyearError.message = "Please fill out this field";
      }
    },
    addressCountry(val){
      if(val){
        if (val === "New Zealand") {
        this.provinceList= this.NewzelandProvinceList;
      } else if (val === "Australia") {
        this.provinceList = this.AusiProvinceList;
      } else if (val === "Fiji") {
        this.provinceList = this.fijiProvinceList;
      } else {
        this.provinceList= [];
      }
      }
      
    },
    addressNum(value) {
      if (value) {
        this.addressNumError.status = true;
      } else {
        this.addressNumError.status = false;
        this.addressNumError.message = "Please fill out this field";
      }
    },
    addressStreet(value) {
      if (value) {
        this.addressStreetError.status = true;
      } else {
        this.addressStreetError.status = false;
        this.addressStreetError.message = "Please fill out this field";
      }
    },
    addressCity(value) {
      if (value) {
        this.addressCityError.status = true;
      } else {
        this.addressCityError.status = false;
        this.addressCityError.message = "Please fill out this field";
      }
    },
    addressZip(value) {
      if (value) {
        this.addressZipError.status = true;
      } else {
        this.addressZipError.status = false;
        this.addressZipError.message = "Please fill out this field";
      }
    },
    addressProvince(value) {
      if (value) {
        this.addressProvinceError.status = true;
      } else {
        this.addressProvinceError.status = false;
        this.addressProvinceError.message = "Please fill out this field";
      }
    },
    customerPhone(value) {
      if(value){
        this.validatePhoneNumber(value);
      }

      // else if (!isValidContactNumber(value)) {
      //   this.customerPhoneError.status = false;
      //   this.customerPhoneError.message = "Contact number is not valid";
      // } else {
      //   this.customerPhoneError.status = true;
      // }
    },
    customerMobile(value) {
      if(value)
      this.validateMobileNumber(value);

      // else if (!isValidContactNumber(value)) {
      //   this.customerMobileError.status = false;
      //   this.customerMobileError.message = "Contact number is not valid";
      // } else {
      //   this.customerMobileError.status = true;
      // }
    },
    customerEmail(val) {
      if (val) {
        if (CheckEmail(val) == true) this.customerEmailError.status = true;
        else {
          this.customerEmailError.status = false;
          this.customerEmailError.message =
            "Please fill in a valid email address";
        }
      } else {
        this.customerEmailError.status = false;
        this.customerEmailError.message = "Please fill in the email address";
      }
    },
    // customerWebsite(value) {
    //   if (value) {
    //     this.customerWebsiteError.status = true;
    //   } else {
    //     this.customerWebsiteError.status = false;
    //     this.customerWebsiteError.message = "Please fill out this field";
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      getBusinessId: "profileSettings/getBusinessId",
    }),
    filteredTimezoneList() {
      if (this.businessCountry === "New Zealand") {
        return this.newzealandTimeoneOption;
      } else if (this.businessCountry === "Australia") {
        return this.australiaTimeoneOption;
      } else if (this.businessCountry === "Fiji") {
        return this.fijiTimeoneOption;
      } else {
        return [];
      }
    },
  },
  async mounted() {
    // await this.initFn();
    // await this.loadCurrentWorkspace();
    // await this.initCurrentWS();
    if (this.getBusinessId) {
      this.initEditBusinesstFn(this.getBusinessId);
      console.log('businessID', this.getBusinessId);
    }

    const res = await GetPackageDetails();
    this.businessMax = res.data.packageDetails.max_Businesses;
    console.log('businessMax', this.businessMax);

    // this.GetBusinessCount();
    const res1 = await GetUserProfile();
    this.businessCount = res1.data.member_of.length;
    console.log('businessCount', this.businessCount);
  },
  methods: {
    validatePhoneNumber(value) {
      this.customerPhoneError.status = null;
      if (!value && !this.businessCountry) {
        this.customerPhoneError.status = false;
        this.customerPhoneError.message = "Please fill in the contact number";
      } else {
        let ans = isValidContactNumber(value, this.businessCountry);
        if (ans === true) {
          this.customerPhoneError.status = true;
        } else {
          this.customerPhoneError.status = false;
          this.customerPhoneError.message = "Invalid contact number";
        }
      }
    },
    validateMobileNumber(value) {
      this.customerMobileError.status = null;
      if (!value && !this.businessCountry) {
        this.customerMobileError.status = false;
        this.customerMobileError.message = "Please fill in the contact number";
      } else {
        let ans = isValidContactNumber(value, this.businessCountry);
        if (ans === true) {
          this.customerMobileError.status = true;
        } else {
          this.customerMobileError.status = false;
          this.customerMobileError.message = "Invalid contact number";
        }
      }
    },
    ...mapActions({
      registerBusiness: "businessRegistration/registerBusiness",
      set_all: "businessRegistration/set_all",
    }),
    async loadCurrentWorkspace() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    async initEditBusinesstFn() {
      this.isEditPage = true;
      try {
        let { data } = await GetSingleWorkspace(this.getBusinessId);
        console.log("🚀 ~ file: BusinessCreate.vue:1293 ~ initEditBusinesstFn ~ data:", data)
        // let { data } = await GetWorkspace();
        console.log("workspace modal get data", data);
        this.businessname = data.name;
        this.ird = data.ird;
        this.bic = data.bic;
        this.nzbn = data.nzbn;
        this.gst = data.gst;
        this.businessCountry = data.country;
        this.addressCountry = data.address.country;
        this.buisness_status = data.buisness_status;
        this.online_payments = data.online_payments;
        this.current_managing_method = data.current_managing_method;
        this.currency = data.currency;
        this.time_zone = data.time_zone;
        this.customers_range = data.customers_range;
        this.businessIndustrytype = data.type;
        this.business_type = data.business_type
        this.fiscalyear = data.fiscal_year;
        this.addressNum = data.address.number;
        this.addressStreet = data.address.street;
        this.addressCity = data.address.city;
        this.addressZip = data.address.zipcode;
        this.addressProvince = data.address.province;
        this.customerPhone = data.phone;
        this.customerMobile = data.mobile;
        this.customerEmail = data.email;
        this.customerWebsite = data.website;

      } catch (error) {
        console.log(error);
      }

    },
    async initCurrentWS() {
      let { data } = await GetCurrentWorkspace();
      this.currentBusinessId = data._id;
      console.log("currentBusinessId", this.currentBusinessId);
    },
    gobackFn() {
      this.$emit("goBackFn");
    },
    async businessCreateFn() {

      if (
        this.businessname == null ||
        this.businessIndustrytype == null ||
        this.businessCountry == null ||
        this.currency == null ||
        this.language == null ||
        this.time_zone == null ||
        this.fiscalyear == null
      ) {
        const errorList = this.errorFn();
        let payloadNotify = {
          isToast: true,
          title:'Business Information',
          content: this.getBusinessId ? "Update Business Information":'Create Business',
          list: errorList,
          variant: "danger",
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
        console.log("All fields are required");
        console.log(this.getBusinessId);
        console.log("current business ID", this.currentBusinessId);
        return;
      }
      if (this.getBusinessId) {
        let payload = {
          id: this.getBusinessId,
          name: this.businessname,
          ird: this.ird,
          bic: this.bic,
          gst: this.gst,
          nzbn: this.nzbn,
          buisness_status: this.buisness_status,
          online_payments: this.online_payments,
          current_managing_method: this.current_managing_method,
          currency: this.currency,
          customers_range: this.customers_range,
          type: this.businessIndustrytype,
          business_type: this.business_type,
          country: this.businessCountry,
          time_zone: this.time_zone,
          language: this.language,
          fiscal_year: this.fiscalyear,
          address: {
            number: this.addressNum,
            street: this.addressStreet,
            city: this.addressCity,
            zipcode: this.addressZip,
            province: this.addressProvince,
            country: this.addressCountry,
          },
          phone: this.customerPhone,
          mobile: this.customerMobile,
          email: this.customerEmail,
          website: this.customerWebsite,
        };
        this.set_all(payload);
        console.log("edit business payload", payload);
        try {
          let { data, status } = await UpdateSingleWorkspace(payload);
          console.log("update business", data);
          if (status == 200) {
            {
              // const profile = await axios.get("/auth/me");
              // const current_role = profile.data.member_of.filter((business) => {
              //   return data.info._id == business.business_id;
              // })[0].role;
              // data.info.role = current_role;
              // commit("SET_CURRENT_WORKSPACE", response.data.info);
              let payloadNotify = {
                isToast: true,
                title: "Business Update",
                content: "The business has been updated successfully",
                variant: "success",
              };
              this.$store.dispatch(
              "auth/setCurrentWorkspaceName",
               this.businessname
            );
              this.$store.dispatch("notification/setNotify", payloadNotify);
              console.log("notify", payloadNotify);
              if (this.getBusinessId == this.currentBusinessId) {
                location.reload();
              }
              this.$emit("goBackFn");
            }
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        // if (this.businessMax == 1) {
        //   if (this.businessCount == 2) {
        //     console.log("Business Cannot be created");
        //   } else {

        //     const data = {
        //       name: this.businessname,
        //       ird: this.ird,
        //       bic: this.bic,
        //       gst: this.gst,
        //       nzbn: this.nzbn,
        //       buisness_status: this.buisness_status,
        //       online_payments: this.online_payments,
        //       current_managing_method: this.current_managing_method,
        //       currency: this.currency,
        //       customers_range: this.customers_range,
        //       type: this.businessIndustrytype,
        //       business_type: this.business_type,
        //       country: this.businessCountry,
        //       time_zone: this.time_zone,
        //       language: this.language,
        //       fiscal_year: this.fiscalyear,
        //       address: {
        //         number: this.addressNum,
        //         street: this.addressStreet,
        //         city: this.addressCity,
        //         zipcode: this.addressZip,
        //         province: this.addressProvince,
        //         country: this.addressCountry,
        //       },
        //       phone: this.customerPhone,
        //       mobile: this.customerMobile,
        //       email: this.customerEmail,
        //       website: this.customerWebsite,
        //     };
        //     // this.set_all(data);
        //     // console.log("data ",data);
        //     // await this.registerBusiness();
        //     let { res } = await createBusiness(data);
        //     console.log("res ", res);
        //     // this.$store.dispatch("profileSettings/setUserBusinesses", data);

        //     this.$emit("goBackFn");

        //   }

        // } else {
          const data = {
            name: this.businessname,
            ird: this.ird,
            bic: this.bic,
            gst: this.gst,
            nzbn: this.nzbn,
            buisness_status: this.buisness_status,
            online_payments: this.online_payments,
            current_managing_method: this.current_managing_method,
            currency: this.currency,
            customers_range: this.customers_range,
            type: this.businessIndustrytype,
            business_type: this.business_type,
            country: this.businessCountry,
            time_zone: this.time_zone,
            language: this.language,
            fiscal_year: this.fiscalyear,
            address: {
              number: this.addressNum,
              street: this.addressStreet,
              city: this.addressCity,
              zipcode: this.addressZip,
              province: this.addressProvince,
              country: this.addressCountry,
            },
            phone: this.customerPhone,
            mobile: this.customerMobile,
            email: this.customerEmail,
            website: this.customerWebsite,
          };
          // this.set_all(data);
          // console.log("data ",data);
          // await this.registerBusiness();
          let { res } = await createBusiness(data);
          console.log("res ", res);
          // this.$store.dispatch("profileSettings/setUserBusinesses", data);
          this.$emit("goBackFn");
        }

      // }
    },
    errorFn() {
      let errorList = [];
        if (!this.businessname) {
          this.businessnameError.status = false;
          this.businessnameError.message =
            "Please enter name for the business";
          errorList.push( this.businessnameError.message)  
        }
        if (!this.businessCountry) {
          this.businessCountryError.status = false;
          this.businessCountryError.message = "Please select business country";
          errorList.push(this.businessCountryError.message)  
        }
        if (!this.currency) {
          this.currencyError.status = false;
          this.currencyError.message = "Please select a currency";
          errorList.push(this.currencyError.message)
        }
        if (!this.language) {
          this.languageError.status = false;
          this.languageError.message = "Please select a language";
          errorList.push(this.languageError.message)
        }
        if (!this.time_zone) {
          this.time_zoneError.status = false;
          this.time_zoneError.message = "Please select a time zone";
          errorList.push(this.time_zoneError.message)
        }
        if (!this.fiscalyear) {
          this.fiscalyearError.status = false;
          this.fiscalyearError.message = "Please select a fiscal year";
          errorList.push(this.fiscalyearError.message)
        }
      return errorList;
    },
    reset() {
      this.businessname == null
      this.businessIndustrytype == null
      this.businessCountry == null
      this.currency == null
      this.language == null
      this.time_zone == null
      this.fiscalyear == null
    }
  },
};
</script>
<style scoped>
.deactivate_btn {
  padding: 10px 30px 10px 30px;
}

.star-mark-color {
  color: red;
}

/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}

.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .filter-dropdown>>>.filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row .filter-dropdown>>>.filter-dropdown-menu button.dropdown-item.active,
.table-card-row .filter-dropdown>>>.filter-dropdown-menu button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
}

/* table element css */
.table-element>>>.table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element>>>.table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element>>>.table-body td {
  vertical-align: middle;
}

.table-element>>>.table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element>>>.table-body .main-text {
  font-size: 0.9rem;
}

.table-element>>>.table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element>>>.table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element>>>.table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element>>>.table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--main-green);
  transition: background-color 200ms ease-in;
}

.table-element>>>.table-body .more-btn>.more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element>>>.table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element>>>.table-body .more-btn:hover>.more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element>>>.table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element>>>.table-body .edit-btn>.edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element>>>.table-body .edit-btn:hover {
  background-color: var(--main-green);
}

.table-element>>>.table-body .edit-btn:hover>.edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-element>>>.table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element>>>.table-body .delete-btn>.delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-element>>>.table-body .delete-btn:hover {
  background-color: var(--danger);
}

.table-element>>>.table-body .delete-btn:hover>.delete-icon {
  color: var(--white);
}

/* expand icon button (default state) */
.table-element>>>.table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element>>>.table-body .expand-btn>.expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}

/* expand icon button (hover state) */
.table-element>>>.table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}

.table-element>>>.table-body .expand-btn:hover>.expand-icon {
  color: var(--white);
}

.table-element>>>.table-body .b-table-details {
  background-color: #fff;
}

.table-element>>>.table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}

/* table pagination */
.table-pagination>>>.custom-page-item button,
.table-pagination>>>.custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}

.table-pagination>>>.custom-page-item button:hover {
  color: var(--main-green);
  border-color: var(--main-green);
  background-color: var(--light);
}

.table-pagination>>>.custom-page-item.active button,
.table-pagination>>>.custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}

.table-pagination>>>.custom-page-item.disabled button,
.table-pagination>>>.custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}

.action-btn {
  height: 50px;
  min-width: 120px;
}

.action-btn:hover {
  background: var(--main-green);
  box-shadow: inset 0px 0px 10px #ffffff;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .action-btn {
    width: 80%;
  }
}
</style>
