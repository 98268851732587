<template>
  <!-- order items modal -->
  <b-modal
    id="delete-account-row-modal"
    ref="delete-account-row-modal"
    title="Remove User"
    size="lg"
    header-class="font-primary"
    header-bg-variant="danger"
    header-text-variant="white"
    header-border-variant="white"
    footer-bg-variant="light"
    footer-border-variant="white"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end mx-0 w-100"
      >
        <b-button
          variant="light"
          class="my-2 my-sm-0 mr-0 mr-sm-2 bg-gray-550 text-white action-btn"
          @click="cancel"
          pill
        >
          Cancel
        </b-button>
        <b-button
          variant="danger"
          class="my-2 my-sm-0 ml-0 ml-sm-2 action-btn"
          pill
          @click="deleteFn"
          :isLoading="isLoading"
          :disabled="isLoading"
        >
          Remove
        </b-button>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
        icon="x-circle"
        class="text-white"
        @click="closeModal()"
        aria-label="Close Modal"
      ></b-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
    >
      <p class="text-left text-dark mb-4">
        Are you sure you want to remove <b> </b> from <b>Personal Account</b>.
      </p>
    </b-row>
  </b-modal>
</template>

<script>
// services

import { deleteInviteUser } from "@/services/invitations.service";

export default {
  name: "DeleteAccountModal",
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    id: String,
    // itemIndex: {
    //   default: null,
    //   type: Number,
    // },
    itemContent: {
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async deleteFn() {
      const id = this.id;
      try {
        const { data } = await deleteInviteUser(id);
        let payloadNotify = {
          isToast: true,
          title: "Delete Invite User",
          content: data.msg,
          variant: "success",
        };
        await this.$store.dispatch("notification/setNotify", payloadNotify);
        this.$emit("onDelete", id);
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "Error!",
          content: error.response.data.msg,
          variant: "danger",
        };
        await this.$store.dispatch("notification/setNotify", payloadNotify);
        console.log(error);
      } finally {
        this.isLoading = false;
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>
<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
