<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- settings container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center settings-card-row w-100"
        no-gutters
      >
        <b-card
          no-body
          class="shadow-sm border-0 settings-container-card w-100"
        >
          <b-row
            cols="12"
            class="d-flex flex-row align-items-start justify-content-start w-100 px-0 mx-0 my-4"
            no-gutters
          >
            <b-tabs
              vertical
              active-nav-item-class="font-weight-bold text-dark"
              class="invoice-tabs mx-0 w-100"
              nav-class="tab-wrapper w-100"
              nav-wrapper-class="custom-tabs-wrapper w-100 p-4"
              content-class="w-100 p-4"
            >
              <b-tab
                no-body
                title="Personal Information"
                title-link-class="tab-nav-link"
              >
                <PersonalInfoTab :itemContent="userProfile" />
              </b-tab>
              <b-tab no-body title="Password" title-link-class="tab-nav-link">
                
                <UserPasswordTab />
              </b-tab>
              <!-- <b-tab no-body title="Notifications" title-link-class="tab-nav-link">
                            <NotificationTab />
                        </b-tab> -->
              <b-tab
                no-body
                title="Businesses"
                title-link-class="tab-nav-link"
                :active="getIsActiveBusinessTab"
                :key="businessTabKey"
                @click="handletab"
              >
                <BusinessSettingsTab ref="businessComponent"/>
              </b-tab>
              <b-tab
                no-body
                title="Payment Setup & Packages"
                title-link-class="tab-nav-link"
                :active="getIsActiveTab"
              >
                <PaymentsTab />
              </b-tab>
            </b-tabs>
            <!-- vertical divider (hidden on medium screens and below) -->
            <!-- <hr class="d-none d-lg-block h-100 border-left" /> -->
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import PersonalInfoTab from "@/components/Settings/Profile/PersonalInfoTab";
import UserPasswordTab from "@/components/Settings/Profile/UserPasswordTab";
import PaymentsTab from "@/components/Settings/Profile/PaymentsTab";
//import NotificationTab from "@/components/Settings/Profile/NotificationTab";
import BusinessSettingsTab from "@/components/Settings/Profile/BusinessSettingsTab";
import { mapGetters } from "vuex";

// services
import { GetUserProfile } from "@/services/settings.service";

export default {
  name: "SettingsProfile",
  components: {
    HomeLayout,
    PersonalInfoTab,
    UserPasswordTab,
    //   NotificationTab,
    BusinessSettingsTab,
    PaymentsTab,
  },

  data() {
    return {
      // isActiveTab: false,
      userProfile: null,
      isShowDeleteItemRow: false,
      showDeleteCustomerModalKey: Number(new Date()) + 100,
      rowItem: null,
      rowIndex: null,
      filterDropDownShow: false,
      isLoading: false,
    };
  },
  watch: {
    // getIsActiveTab(val) {
    //   if (val) {
    //     this.$store.dispatch("profileSettings/setIsCreateBusiness", true);
    //   }
    // }
   
  },
  computed: {
    ...mapGetters({
      getIsActiveTab: "profileSettings/getIsActiveTab",
      getIsActiveBusinessTab: "profileSettings/getIsActiveBusinessTab",
    }),
  },
  async mounted() {
    await this.initFn();
    // if (this.getIsActiveTab == true) {
    //   this.$store.dispatch("profileSettings/setIsCreateBusiness", true);
    // }
  },
  methods: {
    async initFn() {
      let { data } = await GetUserProfile();
      console.log("user data", data);
      this.userProfile = data;
      this.$store.dispatch("profileSettings/setAll", data);
    },
    handletab(){
      // this.$store.dispatch("profileSettings/setBusinessId", null);

      this.$store.dispatch("profileSettings/setIsCreateBusiness", false);
      this.$refs.businessComponent.initFn();
    }
  },
};
</script>

<style>
.custom-tabs-wrapper {
  border-bottom: 1px #dee2e6 solid;
}

@media (min-width: 1100px) {
  .custom-tabs-wrapper {
    border-bottom: none;
    border-right: 1px #dee2e6 solid;
  }
}
</style>

<style scoped>
/* table card */
.settings-card-row {
  height: 100%;
}

.settings-card-row .settings-container-card {
  background-color: var(--white);
  min-width: 100%;
  height: auto;
  /* height: 650px; */
  border-radius: 0;
  border: 1px solid var(--light);
}

.settings-card-row .settings-container-card >>> .tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
}

.settings-card-row .settings-container-card >>> .invoice-tabs {
  /* width: 100%; */
  font-size: 1rem;
  display: grid;
  grid-template-columns: 100%;
  /* overflow: hidden; */
  /* background-color: var(--ghost-white); */
}

.settings-card-row .settings-container-card >>> .invoice-tabs .tab-nav-link {
  font-size: 1rem;
  /* padding-right: 20px;
  padding-top: 20px; */
  /* padding: 10px auto; */
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--main-green);
  /* border-bottom: 1px solid var(--prime-gray); */
}

.settings-card-row
  .settings-container-card
  >>> .invoice-tabs
  .tab-nav-link:hover {
  background-color: var(--light);
  color: var(--main-green);
}

.settings-card-row
  .settings-container-card
  >>> .invoice-tabs
  .tab-nav-link.active {
  background-color: var(--light);
  /* border-bottom: 3px solid var(--primary); */
}

/* table actions row */
.settings-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.settings-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.settings-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}

.settings-card-row .filter-dropdown,
.settings-card-row .add-question-btn {
  height: 45px;
}

.settings-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.settings-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.settings-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* table question action buttons */
/* edit icon button (default state) */
.table-container-row .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-container-row .edit-btn > .edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-container-row .edit-btn:hover {
  background-color: var(--main-green);
}

.table-container-row .edit-btn:hover > .edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-container-row .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-container-row .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-container-row .delete-btn:hover {
  background-color: var(--danger);
}

.table-container-row .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .settings-card-row .filter-dropdown,
  .settings-card-row .export-dropdown {
    min-width: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  /* .insight-cards-row {
    height: auto;
  } */
}

@media (min-width: 1100px) {
  .settings-card-row .settings-container-card >>> .invoice-tabs {
    grid-template-columns: 25% 75%;
  }
}
</style>
